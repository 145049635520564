/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999999999;
  overflow: hidden;
  background-image: url('./images/image2.jpg');
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  color: #d71a21;
}

/* 
#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #e86b19;
  border-top-color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
} */