/* PAGINATION */
.pagination > li > a {
      background: #fafafa;
      color: #666;
}

.pagination.pagination-flat > li > a {
      border-radius: 0 !important;
}

.pagination {
      display: inline-block;
      padding-left: 0;
      margin: 20px 0;
      border-radius: 4px;
}

.pagination > li {
      display: inline;
}

.product__filter .pagination > li > a,
.product__filter .pagination > li > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #000;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
}

.pagination > li > a,
.pagination > li > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #e86b19;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #ddd;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
      z-index: 2;
      color: #23527c;
      background-color: #eee;
      border-color: #ddd;
}

.product__filter .pagination > .active > a,
.product__filter .pagination > .active > a:focus,
.product__filter .pagination > .active > a:hover,
.product__filter .pagination > .active > span,
.product__filter .pagination > .active > span:focus,
.product__filter .pagination > .active > span:hover {
      z-index: 3;
      color: #fff;
      cursor: default;
      background-color: #000 !important;
      border-color: #000 !important;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
      z-index: 3;
      color: #fff;
      cursor: default;
      background-color: #e86b19;
      border-color: #e86b19;
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
      border-color: #ddd;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.3333333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
}

.pager {
      padding-left: 0;
      margin: 20px 0;
      text-align: center;
      list-style: none;
}

.pager li {
      display: inline;
}

.pager li > a,
.pager li > span {
      display: inline-block;
      padding: 5px 14px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 15px;
}

.pager li > a:focus,
.pager li > a:hover {
      text-decoration: none;
      background-color: #eee;
}

.pager .next > a,
.pager .next > span {
      float: right;
}

.pager .previous > a,
.pager .previous > span {
      float: left;
}

.pager .disabled > a,
.pager .disabled > a:focus,
.pager .disabled > a:hover,
.pager .disabled > span {
      color: #777;
      cursor: not-allowed;
      background-color: #fff;
}

thead {
      background: rgba(149, 165, 166, 0.3);
      color: rgba(44, 62, 80, 1);
      border-top: 1px solid #bdc3c7;
}

th .c-container {
      margin-top: 12px;
      margin-bottom: 11px;
}

/* Editor */
.jodit-status-bar-link {
      display: none;
}

.accordion-button:focus,
.accordion-button:not(.collapsed) {
      background-color: white !important;
      box-shadow: none !important;
      color: #000;
}

.accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-icon);
}

.jodit-react-container ol {
      list-style: decimal;
}

.draggable-row {
      cursor: all-scroll;
}

.draggable-row:active {
      cursor: all-scroll;
}

.color-input {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
}

.color-picker {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
}

.remove-btn {
      background-color: #ff0000;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      padding: 6px 12px;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.2s;
}

.remove-btn:hover {
      background-color: #cc0000;
}

.add-color-btn {
      background-color: #4caf50;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      padding: 10px 16px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.2s, transform 0.1s;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      outline: none;
}

.add-color-btn:hover {
      background-color: #45a049;
      transform: scale(1.05);
}

.add-color-btn:active {
      transform: scale(0.95);
}

.btn,
.navbar .navbar-nav > li > a.btn {
      border-radius: 20px;
      /* box-sizing: border-box; */
      border-width: 2px;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 18px;
      border-color: #66615b;
      color: #66615b;
      -webkit-transition: all 150ms linear;
      -moz-transition: all 150ms linear;
      -o-transition: all 150ms linear;
      -ms-transition: all 150ms linear;
      transition: all 150ms linear;
}

.dkuywW {
      display: none;
}

.product-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 10px;
}

.draggable-item {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      background-color: #fff;
      cursor: grab;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      display: flex;
      gap: 20px;
}

.draggable-item img {
      width: 80px;
}

.draggable-row {
      cursor: all-scroll;
}

.draggable-row:active {
      cursor: all-scroll;
}
