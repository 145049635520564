* {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
}

:root {
      --background_color: rgb(238, 77, 45);
      --primary_color: #ee4d2d;
      --border_radius_value: 2px;
}

a {
      text-decoration: none;
}

.form_container {
      padding: 20px;
      background-color: var(--background_color);
}

.form {
      border-radius: 5px;
}

.form_heading_box {
      padding: 0.6875rem 0.875rem;
      color: #ffbf00;
      font-size: 0.875rem;
      font-weight: 700;
      border: 2px solid #ffbf00;
      border-radius: 2px;
      position: relative;
      margin-right: 1rem;
      background-color: #fefaec;
}

.form_heading_box::after {
      position: absolute;
      content: "";
      box-sizing: border-box;
      width: 0.75rem;
      height: 0.75rem;
      transform: rotate(43deg) translateX(-50%);
      border-top: 2px solid #ffbf00;
      border-right: 2px solid #ffbf00;
      top: 50%;
      right: -0.75rem;
      background-color: #fefaec;
}

.form_heading_text {
      font-size: 1.25rem;
      color: #222;
      font-weight: 400;
}

.form_content input {
      border-radius: var(--border_radius_value)
}

.form_content input:focus {
      box-shadow: none;
      border-color: rgb(231, 113, 113);
}

.form_content button {
      color: #fff;
      background-color: var(--primary_color);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.09);
      border-radius: 1px;
}

.form_options a {
      font-size: 13px;
}

.form_alternate_options img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
}

.form_alternate_options a {
      border: 1px solid rgba(0, 0, 0, 0.26);
      border-radius: var(--border_radius_value)
}

.form_footer a {
      color: var(--primary_color);
      font-weight: 500;
      font-size: 15px;
}

.form_footer {
      font-size: 0.875rem;
      color: #bebebe;
}

.form_break {
      position: relative;
      color: #bebebe;
      font-size: 12px;
}