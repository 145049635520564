@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');

:root {
    --border_color: #e6e6e6;
    --link_color: #333333;
    --secondary_color: #25252a;
    --icon_color: #e86b19;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    /* max-width: 1200px; */
}

ul,
li {
    list-style-type: none;
}

a {
    text-decoration: none !important;
    color: #68798b;

}

.dashboard {
    position: relative;
}

.sidebar {
    max-width: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
}

header ::-webkit-scrollbar {
    width: 5px;
}

header ::-webkit-scrollbar-thumb {
    background: #828bb2;
    border-radius: 10px;
}

header ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.sidebar_header {
    border-bottom: 1px solid var(--border_color);
    padding: 10px 20px;
}

.sidebar_header img {
    /* padding: 30px; */
    max-width: 180px;
    height: 45px;
}

.jodit-status-bar-link {
    display: none;
}

.sidebar ul,
li {
    padding-left: 0;
}

.menu_separator {
    color: #68798b;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 700;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.7;
    display: block;
    margin: 24px 0 3px 15px;
}

.menu_bar {
    padding: 4px 7px 4px 28px;
    font-weight: 400;
    color: var(--link_color);
    text-decoration: none;
}

.menu_bar:hover,
.menu_bar:active {
    background: #e86b19;
    color: #fff;
}

.nav_icon i {
    width: 30px;
    font-size: 20px;
}

.nav_title span {
    text-decoration: none !important;
    font-size: 14px;
}

.nav_right_icon i {
    margin-right: 0px;
    color: var(--secondary_color);
}

/* .menu_bar span{
    line-height: 0;
    padding-right: 10px;
} */
.material-icons {
    line-height: 1.5 !important;
    padding-right: 10px;
    font-size: 20px;
}

/* .metismenu .has-arrow::after {
    transform: rotate(225deg) translate(0, -50%) !important;
}

.metismenu .mm-active>.has-arrow::after, .metismenu .has-arrow[aria-expanded=true]::after {
    transform: rotate(315deg) translate(0, -50%) !important;
} */
.metismenu .has-arrow:after {
    transform: rotate(135deg) translate(0, -50%);
}

.metismenu .mm-active>.has-arrow::after,
.metismenu .has-arrow[aria-expanded=true]::after {
    transform: rotate(225deg) translate(0, -50%);
}

#sidebar_nav ul li ul {
    border-left: 1px solid var(--base_color);
}

#sidebar_nav ul li ul {
    padding: 0 0 0 20px;
    margin-left: 32px;
    border-left: 1px solid var(--border_color);
}

.sidebar_nav li ul li {
    padding: 8px 10px;
}

.sidebar_nav li ul li a {
    color: var(--secondary_color);
    font-size: 13px;
    text-transform: capitalize;
    font-weight: 400;

    margin-bottom: 5px;
}

/* main content header css */
.main_content {
    position: absolute;
    top: 0;
    left: 250px;
    width: calc(100% - 250px);
    /* margin-top: 20px; */
    background-color: #f2f2f2;
    min-height: 100vh;
    padding: 20px 20px;
}

.main_header {
    padding: 6px 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem;
}

.header_searchbar input {
    outline: none;
    border: none;
    background: transparent;
}

.main_header span {
    color: var(--link_color);
}

.header_searchbar {
    border-bottom: 1px solid var(--link_color);
    height: 40px;
}

.main_header .header_searchbar span {
    font-size: 17px;
}

.header_function span {
    color: var(--icon_color);
}

.header_link a {
    color: var(--link_color);
}

.slider {
    background-color: #ffffff2b;
    border-radius: 100px;
    padding: 1px;
    margin: 10px;
    cursor: pointer;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, rgba(0, 0, 0, 0.21) 0px 0px 0px 24px inset,
        #929693 0px 0px 0px 0px inset, rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.slider::after {
    content: "";
    display: flex;
    top: 2.3px;
    left: 2px;
    width: 15px;
    height: 15px;
    background-color: #e3e3e3;
    border-radius: 200px;
    position: absolute;
    box-shadow: transparent 0px 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 6px 6px;
    transition: left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    will-change: left, background-color;
}

.switch input[type="checkbox"]:checked+.slider {
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px inset, #7d7d7d 0px 0px 0px 2px inset, #8b8c8b 0px 0px 0px 24px inset,
        rgba(224, 224, 224, 0.45) 0px 1px 0px 0px;
}

.switch input[type="checkbox"]:checked+.slider::after {
    left: 15px;
}

.switch input[type="checkbox"] {
    display: none;
}

/* for dark mode css */
.dark {
    background: #212121;
}

/* Additional Function */
.header_addfunc {
    padding-left: 10px;
}

/* Notification */
.header_notification {
    padding-left: 10px;
}

/* Card */
.panel__content {
    margin-top: 20px;
}

.panel__header {
    background-color: #e86b19;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 10px;
}

.panel__header span {
    color: white;
}

.panel__body {
    background-color: white;
    padding: 20px;
}

.panel__body input {
    border-radius: 0;
}

.panel__body button {
    padding: 10px 30px;
}

.spinner-border {
    height: 20px;
    width: 20px;
    margin-left: 10px;
}